<template>
  <v-col cols="12">
    <PreviewPdfDialog
      v-if="showDialog.preview"
      :value="showDialog.preview"
      :urlPreview="urlPreview"
      :isLoading="isLoadingPreviewFileId"
      @close="showDialog.preview = false"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalItems"
      :options.sync="pagination"
      :loading="loading"
      :items-per-page="20"
      style="min-height: 600px"
      sort-by="createdAt"
      :sort-desc="false"
      :footer-props="{ 'items-per-page-options': itemsPerPage }"
      class="elevation-1"
    >
      <!-- Type -->
      <template v-slot:item.type="{ item }">
        {{ item.type }}
      </template>

      <!-- Date validity -->
      <template v-slot:item.date_start="{ item }">
        {{ formatDates(item.date_start, item.date_end) }}
      </template>

      <!-- File Name -->
      <template v-slot:item.filename="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              style="cursor: pointer"
              v-bind="attrs"
              v-on="on"
              @click="previewCertification(item)"
            >
              {{ item.file ? item.file.filename : "N/A" }}
            </span>
          </template>
          <span>{{ item.file ? item.file.originalname : "N/A" }}</span>
        </v-tooltip>
      </template>

      <!-- Date -->
      <template v-slot:item.createdAt="{ item }">
        {{ item.file ? formatDate(item.file.createdAt) : $t("common.misc.NA") }}
      </template>

      <!-- Status -->
      <template v-slot:item.status="{ item }">
        {{ item.status }}
      </template>

      <!-- Is Visible -->
      <template v-slot:item.isVisible="{ item }">
        <v-switch disabled readonly v-model="item.isVisible" />
      </template>

      <!-- Action -->
      <template v-slot:item.actions="{ item }">
        <!-- Download -->
        <v-btn
          v-if="item.file"
          icon
          @click.stop="download(item)"
          :loading="item.ref === isLoadingDownloadFileId"
        >
          <v-icon small>fas fa-download</v-icon>
        </v-btn>

        <!-- Edit -->
        <v-btn
          icon
          @click.stop="toggleEditDialog(true, item)"
          :loading="item.ref === isLoadingEditFileId"
        >
          <v-icon small>fas fa-pencil</v-icon>
        </v-btn>

        <!-- Delete -->
        <v-btn
          icon
          @click.stop="toggleDeleteDialog(true, item)"
          :loading="item.ref === isLoadingDeleteFileId"
        >
          <v-icon small>fas fa-trash</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Update dialog -->
    <UploadCertificationDialog
      v-if="showDialog.edit"
      :value="showDialog.edit"
      :item="currentFile"
      :URL_API="URL_API.EDIT"
      @close="(needRefresh) => toggleEditDialog(false, null, needRefresh)"
    />

    <!-- Delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :label="currentFile.type"
      @close="toggleDeleteDialog(false)"
      @submit="deleteFile"
    />
  </v-col>
</template>

<script>
import vuetifyDataTable from "@/mixins/vuetifyDataTable";
import formatter from "@/mixins/formatter";

export default {
  name: "CertificationsDatatable",
  mixins: [vuetifyDataTable, formatter],
  components: {
    UploadCertificationDialog: () =>
      import("@/components/Common/Certifications/UploadCertificationDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
    PreviewPdfDialog: () => import("@/components/Common/PreviewPdfDialog"),
  },
  props: {
    URL_API: { type: Object },
  },
  data() {
    return {
      showDialog: {
        edit: false,
        delete: false,
        preview: false,
      },
      headers: [
        {
          text: this.$t("common.certifications.datatable.headers.type"),
          value: "type",
        },
        {
          text: this.$t("common.certifications.datatable.headers.validity"),
          value: "date_start",
        },
        {
          text: this.$t("common.certifications.datatable.headers.filename"),
          value: "filename",
        },
        {
          text: this.$t("common.certifications.datatable.headers.createdAt"),
          value: "createdAt",
        },
        {
          text: this.$t("common.certifications.datatable.headers.status"),
          value: "status",
        },
        {
          text: this.$t("common.certifications.datatable.headers.isVisible"),
          value: "isVisible",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      currentFile: null,
      isLoadingDownloadFileId: null,
      isLoadingPreviewFileId: null,
      isLoadingDeleteFileId: null,
      isLoadingEditFileId: null,
      urlPreview: "",
    };
  },
  methods: {
    toggleEditDialog(val, currentItem = null, needRefresh = false) {
      this.currentFile = currentItem;
      if (needRefresh) this.searchTrigger();
      this.showDialog.edit = val;
    },
    toggleDeleteDialog(val, currentItem = null, needRefresh = false) {
      this.currentFile = currentItem;
      if (needRefresh) this.searchTrigger();
      this.showDialog.delete = val;
    },
    deleteFile() {
      this.isLoadingDeleteFileId = this.currentFile.ref;
      this.$http
        .delete(`${this.URL_API.DELETE}/${this.isLoadingDeleteFileId}`, {
          headers: {
            Authorization: "Bearer " + this.$session.get("jwt"),
          },
        })
        .then((res) => {
          this.toggleDeleteDialog(false, null, true);
        })
        .catch((err) => {
          this.$store.commit("toast/showError", this.$t("common.errors.500"));
        })
        .finally(() => {
          this.isLoadingDeleteFileId = null;
        });
    },
    download(file) {
      this.isLoadingDownloadFileId = file.ref;
      this.$http
        .get(
          `${this.URL_API.DOWNLOAD}/${this.isLoadingDownloadFileId}/download`,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + this.$session.get("jwt"),
            },
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.file.originalname);
          //document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          this.$store.commit("toast/showError", this.$t("common.errors.500"));
        })
        .finally(() => {
          this.isLoadingDownloadFileId = null;
        });
    },
    previewCertification(item) {
      this.showDialog.preview = true;
      this.isLoadingPreviewFileId = item.ref;
      this.$http
        .get(
          `${this.URL_API.DOWNLOAD}/${this.isLoadingPreviewFileId}/download`,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + this.$session.get("jwt"),
            },
          }
        )
        .then((res) => {
          if (res.headers.mimetype) {
            let url = window.URL.createObjectURL(
              new Blob([res.data], { type: `${res.headers.mimetype}` })
            );
            this.urlPreview = url;
          }
        })
        .catch((err) => {
          this.$store.commit("toast/showError", this.$t("common.errors.500"));
        })
        .finally(() => {
          this.isLoadingPreviewFileId = null;
        });
    },
  },
};
</script>
